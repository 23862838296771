import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AdminCard from '../../components/AdminCard';
import Breadcrumbs from '../../components/Breadcrumbs';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CustomerForm from '../../components/CustomerForm';
import Layout from '../../components/Layout';
import OverlayBox from '../../components/OverlayBox';
import Plus from '../../components/Plus';
import TabCard from '../../components/TabCard';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { colors } from '../../utils/siteVars';
import { adminEditUserDefault } from '../../utils/defaults';
import { pdfTemplateTypes } from '../../utils/data';
import { progressRequest, request } from '../../utils/request';
import { parseCustomerName } from '../../utils/parseCustomerName';
import { downloadPdf } from '../../utils/downloadPdf';
import { trimObjectKeys } from '../../utils/trimObjectKeys';
import { pick } from '../../utils/pick';
import UserCard from '../../components/UserCard';
import UserForm from '../../components/UserForm';
import AdminCustomerCard from '../../components/AdminCustomerCard';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import MunipRegForm from '../../components/MunipRegForm';

const StyledAdminPage = styled.div`
  .file-input {
    display: none;
  }
  .add-protocol-option-container {
    display: flex;
    button {
      margin-left: 0.5rem;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
  .munip-reg-row {
    gap: 1rem;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: flex-start;
    position: relative;
    padding: 1rem 0;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${colors.mediumGrey};
      content: '';
    }
    input {
      margin: 0;
    }
    .munip-row-buttons-container {
      justify-self: flex-end;
      button {
        margin: 0;
        margin-left: 0.5rem;
      }
    }
  }
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  h5 {
    margin-bottom: 0.7rem !important;
  }
  p.strong {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .search-box {
    position: relative;
    button {
      border: 1px solid ${colors.darkGrey};
      position: absolute;
      top: 0.3rem;
      right: 0.3rem;
      margin: 0;
    }
    input {
      min-width: 250px;
    }
  }
  .mbottom05 {
    margin-bottom: 0.5rem !important;
  }

  @media (max-width: 1000px) {
    .dual {
      grid-template-columns: 1fr;
    }
  }
`;

const customerDeleteDefault = {
  active: false,
  customer: {},
};
const templateDeleteDefault = {
  active: false,
  typeName: '',
  fileName: '',
};
const userDeleteDefault = {
  active: false,
  user: {},
};
const munipRegEditDefault = {
  customerName: '',
  munipName: '',
  id: '',
};

const AdminPage = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customers, setCustomers] = useState([]);
  const [activeCustomersTab, setActiveCustomersTab] = useState(0);
  const [activeUsersTab, setActiveUsersTab] = useState(0);
  const [users, setUsers] = useState([]);
  const [munipReg, setMunipReg] = useState([]);
  const [templateFilesList, setTemplateFilesList] = useState([]);
  const [customersSearchString, setCustomersSearchString] = useState('');
  const [
    protocolOptionsSelectedType,
    setProtocolOptionsSelectedType,
  ] = useState('');
  const [protocolOptions, setProtocolOptions] = useState({ insp: [] });
  const [editUserFormData, setEditUserFormData] = useState(
    adminEditUserDefault
  );
  const [customerDeletePending, setCustomerDeletePending] = useState(
    customerDeleteDefault
  );
  const [munipRegActiveChange, setMunipRegActiveChange] = useState(null);

  const [templateDeletePending, setTemplateDeletePending] = useState(
    templateDeleteDefault
  );
  const [userDeletePending, setUserDeletePending] = useState(userDeleteDefault);
  const [munipRegActiveChangeData, setMunipRegActiveChangeData] = useState(
    munipRegEditDefault
  );
  const [regFormOpen, setRegFormOpen] = useState(false);

  const getCustomers = async (e) => {
    if (e) e.preventDefault();
    if (customersSearchString.length < 3) return;

    const { data } = await request({
      state,
      dispatch,
      path: `customers/?q=${customersSearchString}&page=${0}&sortOrder=name`,
      errorText: 'Kunde inte hämta kunder',
    });
    if (!data) return;
    setCustomers(data.customers);
  };

  const getAdminData = async () => {
    setCustomersSearchString('');
    const adminDataPromise = request({
      state,
      dispatch,
      path: 'customers/admindata-all-but-customers',
    });
    const fileListPromise = request({
      state,
      dispatch,
      isDoServer: true,
      path: 'files/{token}/templates/list',
    });
    const [{ data: adminData }, { data: fileList }] = await Promise.all([
      adminDataPromise,
      fileListPromise,
    ]);

    if (!adminData || !fileList) return;
    setUsers(adminData.users);
    setMunipReg(adminData.munipreg);
    setTemplateFilesList(fileList);
    const protocolOptsMap = adminData.protocolOptions.reduce((acc, curr) => {
      acc[curr.protocolType] = curr.keys;
      return acc;
    }, {});
    setProtocolOptions(protocolOptsMap);
  };

  const deleteCustomerInit = (customer) => {
    setCustomerDeletePending({
      active: true,
      customer: customer,
    });
  };

  const deleteCustomer = async (customer) => {
    await request({
      state,
      dispatch,
      path: `customers/${customer._id}`,
      method: 'DELETE',
    });
    await request({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/delete-customer-directory/${parseCustomerName(
        customer
      )}/${customer._id}`,
      successText: 'Kund raderad',
      method: 'DELETE',
    });

    getAdminData();
    getCustomers();
    setCustomerDeletePending(customerDeleteDefault);
  };

  const addCustomer = async (formData) => {
    await request({
      state,
      dispatch,
      path: 'customers/add',
      method: 'POST',
      body: { customer: formData },
    });

    const customerName = parseCustomerName(formData);
    await request({
      state,
      dispatch,
      isDoServer: true,
      method: 'POST',
      path: `files/{token}/create-customer-directories/${customerName}`,
      successText: 'Sparat',
    });
    setActiveCustomersTab(0);
    getAdminData();
  };

  const downloadTemplate = async (pdfTemplateType, fileName) => {
    const filePath = encodeURIComponent(`${pdfTemplateType}/${fileName}`);
    const { data } = await request({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/templates/single/${filePath}`,
    });

    if (!data) return;
    downloadPdf(data.data, fileName, true);
  };

  const deleteSingleTemplate = async (template) => {
    const { typeName, fileName } = template;
    const filePath = encodeURIComponent(`${typeName}/${fileName}`);
    await request({
      state,
      dispatch,
      isDoServer: true,
      path: `files/{token}/templates/single/${filePath}`,
      method: 'DELETE',
      successText: 'Mall raderad',
    });
    setTemplateDeletePending(templateDeleteDefault);
    getAdminData();
  };

  const readTemplateFile = async (e, pdfTemplateType) => {
    if (!e.target.files?.length) return;
    const formData = new FormData();
    formData.append('template', e.target.files[0]);
    await progressRequest({
      state,
      dispatch,
      body: formData,
      method: 'POST',
      path: `files/{token}/templates/${pdfTemplateType}`,
      successText: 'Filen har laddats upp',
    });
    const input = document.getElementById(`fileInput${pdfTemplateType}`);
    if (input) input.value = null;
    getAdminData();
  };

  const deleteUserInit = (user) => {
    setUserDeletePending({
      active: true,
      user: user,
    });
  };

  const deleteUser = async (user) => {
    await request({
      state,
      dispatch,
      method: 'DELETE',
      successText: 'Användaren raderades',
      path: `users/auth/delete-user/${user._id}`,
    });
    setUserDeletePending(userDeleteDefault);
    getAdminData();
  };

  const updateUserInit = (user) => {
    setEditUserFormData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      phone: user.phone,
      active: true,
      _id: user._id,
    });
  };

  const updateUser = async (user) => {
    const pickedFormData = pick(user, [
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
    ]);
    const cleanedFormData = trimObjectKeys(pickedFormData);
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      body: { user: cleanedFormData },
      path: `users/auth/update/${user._id}`,
      successText: 'Användare uppdaterad',
    });

    if (err && err === 'Email exists') {
      return dispatch({ type: 'ALERT', content: 'Mailadressen finns redan' });
    }
    setEditUserFormData(adminEditUserDefault);
    getAdminData();
  };

  const addUser = async (user) => {
    if (user.password !== user.passwordRepeat) {
      return dispatch({ type: 'ALERT', content: 'Lösenorden matchar inte' });
    }

    const pickedFormData = pick(user, [
      'firstName',
      'lastName',
      'email',
      'phone',
      'role',
      'password',
    ]);
    const cleanedFormData = trimObjectKeys(pickedFormData);

    const { err } = await request({
      state,
      dispatch,
      successText: 'Användare sparad',
      method: 'POST',
      body: { user: cleanedFormData },
      path: 'users/auth/register',
    });

    if (err) {
      if (err === 'Email exists') {
        dispatch({ type: 'ALERT', content: 'Mailadressen finns redan' });
      }
      if (err.details?.[0]?.path?.[0] === 'password') {
        dispatch({
          type: 'ALERT',
          content:
            'Lösenordet måste vara minst 10 tecken långt och innehålla både stora och små bokstäver, samt minst en siffra',
        });
      }
      return;
    }
    setActiveUsersTab(0);

    getAdminData();
  };

  const addMunipReg = async (munipReg) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Sparad',
      path: 'munipreg',
      body: { munipReg },
    });
    if (err) return;
    setRegFormOpen(false);
    getAdminData();
  };

  const updateMunipReg = async () => {
    const body = {
      munipReg: pick(munipRegActiveChangeData, ['customerName', 'munipName']),
    };

    const { err } = await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Uppdaterad',
      path: `munipreg/update/${munipRegActiveChangeData._id}`,
      body,
    });
    if (err) return;
    setMunipRegActiveChangeData(munipRegEditDefault);
    setMunipRegActiveChange(null);
    getAdminData();
  };

  const deleteReg = async (id) => {
    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      path: `munipreg/${id}`,
      successText: 'Raderad',
    });
    if (err) return;
    setMunipRegActiveChangeData(munipRegEditDefault);
    getAdminData();
    setMunipRegActiveChange(null);
  };

  const munipRegInitChange = (reg, i) => {
    setMunipRegActiveChange(i);
    setMunipRegActiveChangeData(
      pick(reg, ['munipName', 'customerName', '_id'])
    );
  };

  const updateProtocolOptions = async () => {
    const body = {
      protocolType: protocolOptionsSelectedType,
      keys: protocolOptions[protocolOptionsSelectedType],
    };
    await request({
      state,
      dispatch,
      method: 'POST',
      successText: 'Protokollalternativ uppdaterades',
      body,
      path: `protocol-options/update/${protocolOptionsSelectedType}`,
    });
    getAdminData();
  };

  useEffect(() => {
    if (state.token) getAdminData();
    // eslint-disable-next-line
  }, [state.token]);

  return (
    <Layout>
      <StyledAdminPage data-test="admin-index-page">
        <h1 className="page-header">Admin</h1>
        <Breadcrumbs crumbs={[{ to: '/home', p: 'Hem' }, { p: 'Admin' }]} />
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <TabCard
              data-test={'customers-tabber'}
              activeTab={activeCustomersTab}
              setActiveTab={setActiveCustomersTab}
              tabs={['Hantera kunder', 'Lägg till kund']}
              slots={[
                <form onSubmit={getCustomers} className="search-box">
                  <input
                    type="text"
                    data-test="customers-admin-search"
                    value={customersSearchString}
                    onChange={(e) => setCustomersSearchString(e.target.value)}
                    placeholder="Sök kund"
                  />
                  <Button
                    data-test="customers-admin-search-submit"
                    small
                    type="submit"
                    disabled={customersSearchString.length < 3}
                  >
                    Sök
                  </Button>
                </form>,
              ]}
              contents={[
                customers.map((customer) => (
                  <AdminCustomerCard
                    key={customer._id}
                    customer={customer}
                    deleteCustomer={deleteCustomerInit}
                  />
                )),
                <CustomerForm handleSubmit={addCustomer} />,
              ]}
            />
            <TabCard
              activeTab={activeUsersTab}
              setActiveTab={setActiveUsersTab}
              tabs={['Hantera användare', 'Lägg till användare']}
              contents={[
                users.map((user) => (
                  <UserCard
                    key={user._id}
                    user={user}
                    editUser={updateUserInit}
                    deleteUser={deleteUserInit}
                  />
                )),
                <UserForm handleSubmit={addUser} />,
              ]}
            />
            <Card>
              <div className="customer-card-header">
                <h3>Protokollalternativ</h3>
                <div className="customer-card-header-right-content">
                  <Button noBg onClick={updateProtocolOptions}>
                    Spara
                  </Button>
                </div>
              </div>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select
                value={protocolOptionsSelectedType}
                onChange={(e) => setProtocolOptionsSelectedType(e.target.value)}
              >
                <option value="">Välj protokolltyp</option>
                {Object.keys(protocolOptions).map((key) => (
                  <option key={key}>{key}</option>
                ))}
              </select>
              <br />
              {protocolOptions[protocolOptionsSelectedType]?.map(
                (option, optionIndex) => (
                  <div key={option._id}>
                    <h5>{option.placeholder}</h5>
                    <div className="dual">
                      <div>
                        <p className="strong">Resultat</p>
                        {option.results.map((result, resultIndex) => (
                          <div
                            className="add-protocol-option-container"
                            key={option._id + 'result' + resultIndex}
                          >
                            <input
                              type="text"
                              placeholder="Nytt alternativ"
                              value={result}
                              onChange={(e) => {
                                const tempOptions = [
                                  ...protocolOptions[
                                    protocolOptionsSelectedType
                                  ],
                                ];
                                tempOptions[optionIndex].results[resultIndex] =
                                  e.target.value;
                                setProtocolOptions({
                                  ...protocolOptions,
                                  [protocolOptionsSelectedType]: tempOptions,
                                });
                              }}
                            />
                            <Button
                              look="danger"
                              small
                              onClick={() => {
                                const tempOptions = [
                                  ...protocolOptions[
                                    protocolOptionsSelectedType
                                  ],
                                ];
                                tempOptions[optionIndex].results.splice(
                                  resultIndex,
                                  1
                                );
                                setProtocolOptions({
                                  ...protocolOptions,
                                  [protocolOptionsSelectedType]: tempOptions,
                                });
                              }}
                            >
                              <Plus cross color={colors.white} />
                            </Button>
                          </div>
                        ))}
                        <Button
                          small
                          onClick={() => {
                            const tempOptions = [
                              ...protocolOptions[protocolOptionsSelectedType],
                            ];
                            tempOptions[optionIndex].results.push('');
                            setProtocolOptions({
                              ...protocolOptions,
                              [protocolOptionsSelectedType]: tempOptions,
                            });
                          }}
                        >
                          Lägg till alternativ
                        </Button>
                      </div>
                      {protocolOptionsSelectedType !== 'RR' &&
                      protocolOptionsSelectedType !== 'MR' ? (
                        <div>
                          <p className="strong">Rekommendationer</p>
                          {option.recommendations.map(
                            (recommendation, recommendationIndex) => (
                              <div
                                className="add-protocol-option-container"
                                key={option._id + 'rec' + recommendationIndex}
                              >
                                <input
                                  type="text"
                                  placeholder="Nytt alternativ"
                                  value={recommendation}
                                  onChange={(e) => {
                                    const tempOptions = [
                                      ...protocolOptions[
                                        protocolOptionsSelectedType
                                      ],
                                    ];
                                    tempOptions[optionIndex].recommendations[
                                      recommendationIndex
                                    ] = e.target.value;
                                    setProtocolOptions({
                                      ...protocolOptions,
                                      [protocolOptionsSelectedType]: tempOptions,
                                    });
                                  }}
                                />
                                <Button
                                  look="danger"
                                  small
                                  onClick={() => {
                                    const tempOptions = [
                                      ...protocolOptions[
                                        protocolOptionsSelectedType
                                      ],
                                    ];
                                    tempOptions[
                                      optionIndex
                                    ].recommendations.splice(
                                      recommendationIndex,
                                      1
                                    );
                                    setProtocolOptions({
                                      ...protocolOptions,
                                      [protocolOptionsSelectedType]: tempOptions,
                                    });
                                  }}
                                >
                                  <Plus cross color={colors.white} />
                                </Button>
                              </div>
                            )
                          )}
                          <Button
                            small
                            onClick={() => {
                              const tempOptions = [
                                ...protocolOptions[protocolOptionsSelectedType],
                              ];
                              tempOptions[optionIndex].recommendations.push('');
                              setProtocolOptions({
                                ...protocolOptions,
                                [protocolOptionsSelectedType]: tempOptions,
                              });
                            }}
                          >
                            Lägg till alternativ
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                  </div>
                )
              )}
            </Card>
          </div>
          <div className="single-customer-right-content">
            <Card>
              <div className="customer-card-header">
                <h3>Dokumentmallar</h3>
              </div>
              {pdfTemplateTypes.map((templateType) => (
                <div
                  className="document-templates-container"
                  key={templateType.name}
                >
                  <p
                    className="template-type"
                    style={{
                      color: colors.mediumGrey,
                      marginBottom: '-0.5rem',
                    }}
                  >
                    <strong>{templateType.name}</strong>
                  </p>
                  <input
                    accept=".pdf,.doc,.docx,.pages"
                    className="file-input"
                    id={`fileInput${templateType.name}`}
                    type="file"
                    onChange={(e) => {
                      readTemplateFile(e, templateType.name);
                    }}
                  />
                  {templateFilesList[templateType.key]?.map(
                    (fileName, index) => (
                      <AdminCard
                        key={fileName + templateType.name + index}
                        cardContent={<p>{fileName}</p>}
                        menuContent={
                          <>
                            <Button
                              small
                              onClick={() =>
                                downloadTemplate(templateType.name, fileName)
                              }
                            >
                              Ladda ned
                            </Button>
                            <Button
                              small
                              look="danger"
                              onClick={() => {
                                setTemplateDeletePending({
                                  active: true,
                                  typeName: templateType.name,
                                  fileName: fileName,
                                });
                              }}
                            >
                              Radera
                            </Button>
                          </>
                        }
                      />
                    )
                  )}
                  <Button
                    style={{
                      marginTop: '1rem',
                      marginBottom: '2rem',
                    }}
                    small
                    onClick={() => {
                      document
                        .getElementById(`fileInput${templateType.name}`)
                        .click();
                    }}
                  >
                    Ladda upp
                  </Button>
                </div>
              ))}
            </Card>
            <Card>
              <div className="customer-card-header">
                <h3>Kommunregister</h3>
                <div className="customer-card-header-right-content">
                  <Button noBg onClick={() => setRegFormOpen(true)}>
                    Lägg till
                  </Button>
                </div>
              </div>
              <div className="munip-reg-container">
                <div className="munip-reg-row">
                  <p>
                    <strong>Kommun</strong>
                  </p>
                  <p>
                    <strong>Kundnamn</strong>
                  </p>
                </div>
              </div>
              <div />
              {munipReg.map((reg, index) => (
                <div className="munip-reg-row" key={reg._id}>
                  {munipRegActiveChange !== index ? (
                    <>
                      <p>{reg.munipName}</p>
                      <p>{reg.customerName}</p>
                      <div className="munip-row-buttons-container">
                        <Button
                          small
                          onClick={() => {
                            munipRegInitChange(reg, index);
                          }}
                        >
                          Ändra
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder="Kommunnamn"
                        value={munipRegActiveChangeData.munipName}
                        name="munipName"
                        onChange={(e) => {
                          setMunipRegActiveChangeData({
                            ...munipRegActiveChangeData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Kundnamn"
                        value={munipRegActiveChangeData.customerName}
                        name="customerName"
                        onChange={(e) => {
                          setMunipRegActiveChangeData({
                            ...munipRegActiveChangeData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      />
                      <div className="munip-row-buttons-container">
                        <Button
                          small
                          onClick={() => {
                            updateMunipReg();
                          }}
                        >
                          Spara
                        </Button>
                        <Button
                          small
                          look="danger"
                          onClick={() => deleteReg(reg._id)}
                        >
                          Radera
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </Card>
          </div>
        </div>
        {customerDeletePending.active && (
          <DeleteConfirmation
            itemIdentifier={
              customerDeletePending.customer.customerInformation?.name
            }
            itemName={'kund'}
            close={() => setCustomerDeletePending(customerDeleteDefault)}
            item={customerDeletePending.customer}
            commit={deleteCustomer}
          />
        )}
        {templateDeletePending.active && (
          <DeleteConfirmation
            close={() => setTemplateDeletePending(templateDeleteDefault)}
            commit={deleteSingleTemplate}
            itemIdentifier={templateDeletePending.fileName}
            item={templateDeletePending}
            itemName={'fil'}
          />
        )}
        {userDeletePending.active && (
          <DeleteConfirmation
            close={() => setUserDeletePending(userDeleteDefault)}
            commit={deleteUser}
            item={userDeletePending.user}
            itemName="användare"
            itemIdentifier={`${userDeletePending.user.firstName} ${userDeletePending.user.lastName}`}
          />
        )}
        {editUserFormData.active && (
          <OverlayBox
            active
            close={() => setEditUserFormData(adminEditUserDefault)}
            header="Ändra användare"
          >
            <UserForm handleSubmit={updateUser} data={editUserFormData} />
          </OverlayBox>
        )}
        {regFormOpen && (
          <OverlayBox
            active
            close={() => setRegFormOpen(false)}
            header={'Lägg till i kommunregister'}
          >
            <MunipRegForm handleSubmit={addMunipReg} />
          </OverlayBox>
        )}
      </StyledAdminPage>
    </Layout>
  );
};

export default AdminPage;
