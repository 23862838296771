import React from 'react';
import styled from 'styled-components';
import AdminCard from './AdminCard';
import Button from './Button';
import { Link } from 'gatsby';

const StyledAdminCustomerCard = styled.div``;
const AdminCustomerCard = ({ customer, deleteCustomer }) => {
  return (
    <StyledAdminCustomerCard>
      <AdminCard
        data-test={'customers-admin-card'}
        cardContent={customer.customerInformation?.name}
        menuContent={
          <>
            <Link to={`/customers/single?id=${customer._id}`}>
              <Button small>Visa</Button>
            </Link>
            <Link to="/admin/edit-customer" state={{ formData: customer }}>
              <Button small>Redigera</Button>
            </Link>
            <Button
              data-test="delete-customer-button"
              small
              look="danger"
              onClick={() => deleteCustomer(customer)}
            >
              Radera
            </Button>
          </>
        }
      />
    </StyledAdminCustomerCard>
  );
};

export default AdminCustomerCard;
