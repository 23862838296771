import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledMunipRegForm = styled.form``;

const MunipRegForm = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({ customerName: '', munipName: '' });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <StyledMunipRegForm onSubmit={onSubmit}>
      <input
        type="text"
        value={formData.munipName}
        placeholder="Kommunnamn"
        name="munipName"
        onChange={onChange}
      />
      <input
        type="text"
        value={formData.customerName}
        placeholder="Kundnamn"
        name="customerName"
        onChange={onChange}
      />
      <Button type="submit">Spara</Button>
    </StyledMunipRegForm>
  );
};

export default MunipRegForm;
