import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { adminUserDefault } from '../utils/defaults';
import Button from './Button';

const StyledUserForm = styled.form``;

const UserForm = ({ handleSubmit, data }) => {
  const [formData, setFormData] = useState(adminUserDefault);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledUserForm onSubmit={onSubmit}>
      <input
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={onChange}
        required
        placeholder="Förnamn"
      />
      <input
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={onChange}
        required
        placeholder="Efternamn"
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={onChange}
        required
        placeholder="E-post"
      />
      <input
        type="phone"
        name="phone"
        value={formData.phone}
        onChange={onChange}
        placeholder="Telefonnummer"
      />
      {!formData._id && (
        <>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={onChange}
            required
            placeholder="Lösenord"
          />
          <input
            type="password"
            name="passwordRepeat"
            value={formData.passwordRepeat}
            onChange={onChange}
            required
            placeholder="Upprepa lösenord"
          />
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-onchange */}
      <select name="role" value={formData.role} onChange={onChange} required>
        <option disabled value="">
          Roll
        </option>
        <option value="Tekniker">Tekniker</option>
        <option value="Admin">Admin</option>
      </select>
      <Button type="submit" med>
        Spara
      </Button>
    </StyledUserForm>
  );
};

export default UserForm;
