import React from 'react';
import styled from 'styled-components';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { useContext } from 'react';
import AdminCard from './AdminCard';
import Button from './Button';

const StyledUserCard = styled.div``;

const UserCard = ({ user, editUser, deleteUser }) => {
  const state = useContext(GlobalStateContext);

  const { firstName, lastName, role, _id } = user;
  const name = `${firstName} ${lastName}`;
  const isMe = _id === state.user._id;

  return (
    <StyledUserCard>
      <AdminCard
        key={user._id}
        cardContent={
          <div>
            <p>{name}</p>
            <p className="light">{`${role} ${isMe ? '(Du)' : ''}`}</p>
          </div>
        }
        menuContent={
          <>
            <Button small onClick={() => editUser(user)}>
              Ändra
            </Button>
            {!isMe && (
              <Button small look="danger" onClick={() => deleteUser(user)}>
                Radera
              </Button>
            )}
          </>
        }
      />
    </StyledUserCard>
  );
};

export default UserCard;
